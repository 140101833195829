var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":"","align-content-start":""}},[_c('v-flex',{attrs:{"xs12":"","pa-4":"","text-center":""}},[_c('span',{staticClass:"font-weight-bold text-h4"},[_vm._v(" Becas UNICAES "+_vm._s(_vm.sede == 'SA' ? 'Santa Ana' : 'ILOBASCO')+" ciclo "+_vm._s(_vm.selectCiclo)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm2":"","pa-4":""}},[_c('v-autocomplete',{attrs:{"label":"Ciclo a mostrar","no-data-text":"Sin resultados","items":_vm.cmbCiclos},model:{value:(_vm.selectCiclo),callback:function ($$v) {_vm.selectCiclo=$$v},expression:"selectCiclo"}})],1),_c('v-flex',{attrs:{"xs12":"","pa-4":""}},[_c('span',{staticClass:"font-weight-bold text-h4 colorPrimary"},[_c('countTo',{attrs:{"start-val":0,"end-val":_vm.cantBecadosAprobados,"duration":3000}}),_vm._v(" Becas aprobadas ")],1),_c('br'),_c('span',{staticClass:"font-weight-bold text-h5",staticStyle:{"color":"#616161"}},[_c('countTo',{attrs:{"start-val":0,"end-val":_vm.cantBecadosAprobadosConsejo,"duration":3000}}),_vm._v(" Becas aprobadas por consejo ")],1)]),_c('v-flex',{attrs:{"xs12":"","sm8":"","pa-4":""}},[_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"dense":"","mobile-breakpoint":0,"headers":_vm.headersTipoBeneficario,"items":_vm.datosTipoBeneficiario,"item-key":"name","disable-pagination":"","hide-default-footer":"","loading":_vm.loadingTipoBeneficiario,"loading-text":"Cargando datos, por favor espere ...","no-data-text":"Sin datos"},scopedSlots:_vm._u([{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(_vm.numFormatIntl(item.monto))+" ")]}},{key:"body.append",fn:function(ref){return [_c('tr',[_c('td',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(" Total general ")]),_c('td',{staticClass:"font-weight-bold text-subtitle-1",staticStyle:{"text-align":"right"}},[_vm._v(" $ "+_vm._s(_vm.totalMontoTipoBeneficiario)+" ")]),_c('td',{staticClass:"font-weight-bold text-subtitle-1",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.totalNumBecaTipoBeneficiario)+" ")])])]}}])})],1),_c('v-flex',{staticClass:"d-flex flex-column justify-space-between",attrs:{"xs12":"","sm4":"","pa-4":""}},[_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"dense":"","items":_vm.datosBienestar,"mobile-breakpoint":0,"loading":_vm.loadingBienestar,"headers":_vm.headersBienestar,"item-key":"key","disable-pagination":"","hide-default-footer":"","no-data-text":"Sin datos"},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.splitNombreBienestar(item.key))+" ")]}},{key:"body.append",fn:function(ref){return [_c('tr',[_c('td',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v("Total")]),_c('td',{staticClass:"font-weight-bold text-subtitle-1 text-center"},[_vm._v(" "+_vm._s(_vm.totalBienestar)+" ")])])]}}])})],1),_c('v-flex',{attrs:{"xs12":"","pa-4":""}},[_c('v-card',{class:{
            'd-flex flex-column align-center justify-center':
               _vm.$vuetify.breakpoint.smAndDown,
            'd-flex flex-row align-center justify-center':
               _vm.$vuetify.breakpoint.mdAndUp
         }},[_c('v-layout',{staticClass:"text-h6",attrs:{"align-center":"","justify-center":""}},[_vm._v("Mostrar datos de tipo de beneficio por:")]),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',{staticClass:"text-h6 pr-2"},[_vm._v("N° de becas")]),_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.showMontoTipoBeneficiario),callback:function ($$v) {_vm.showMontoTipoBeneficiario=$$v},expression:"showMontoTipoBeneficiario"}}),_c('span',{staticClass:"text-h6 pl-2"},[_vm._v("Suma total")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-4":""}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_vm._v(" Becados por tipo de beneficiario ")]),_c('apexchart',{attrs:{"height":"350","width":"100%","type":"bar","options":_vm.chBarTipoBeneficarioOptions,"series":_vm.chBarTipoBeneficarioSeries}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-4":""}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_vm._v(" Porcentajes por tipo de beneficiario ")]),_c('apexchart',{attrs:{"height":"350","width":"100%","type":"pie","options":_vm.chPieTipoBeneficarioOptions,"series":_vm.chPieTipoBeneficarioSeries}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","pa-4":""}},[_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"dense":"","mobile-breakpoint":0,"headers":_vm.headersCarrera,"items":_vm.datosCarrera,"item-key":"name","fixed-header":"","height":_vm.datosCarrera.length > 10 ? '450' : '',"disable-pagination":"","hide-default-footer":"","loading":_vm.loadingCarrera,"loading-text":"Cargando datos, por favor espere ...","no-data-text":"Sin datos"},scopedSlots:_vm._u([{key:"item.monto",fn:function(ref){
         var item = ref.item;
return [_vm._v(" $ "+_vm._s(_vm.numFormatIntl(item.monto))+" ")]}},{key:"body.append",fn:function(ref){return [_c('tr',[_c('td',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(" Total general ")]),_c('td',{staticClass:"font-weight-bold text-subtitle-1",staticStyle:{"text-align":"right"}},[_vm._v(" $ "+_vm._s(_vm.totalMontoTipoBeneficiario)+" ")]),_c('td',{staticClass:"font-weight-bold text-subtitle-1",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.totalNumBecaTipoBeneficiario)+" ")])])]}}])})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","pa-4":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Mostrar datos de carreras por:")]),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',{staticClass:"text-h6 pr-2"},[_vm._v("N° de becas")]),_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.showMontoCarrera),callback:function ($$v) {_vm.showMontoCarrera=$$v},expression:"showMontoCarrera"}}),_c('span',{staticClass:"text-h6 pl-2"},[_vm._v("Suma total")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","pa-4":""}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_vm._v(" Becados por carrera ")]),_c('apexchart',{attrs:{"height":"400","width":"100%","type":"bar","options":_vm.chBarCarreraOptions,"series":_vm.chBarCarreraSeries}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }