<template>
   <v-layout wrap align-content-start>
      <v-flex xs12 pa-4 text-center>
         <span class="font-weight-bold text-h4">
            Becas UNICAES
            {{ sede == 'SA' ? 'Santa Ana' : 'ILOBASCO' }} ciclo
            {{ selectCiclo }}
         </span>
      </v-flex>
      <v-flex xs12 sm2 pa-4>
         <v-autocomplete
            v-model="selectCiclo"
            label="Ciclo a mostrar"
            no-data-text="Sin resultados"
            :items="cmbCiclos"
         ></v-autocomplete>
      </v-flex>
      <v-flex xs12 pa-4>
         <span class="font-weight-bold text-h4 colorPrimary">
            <countTo
               :start-val="0"
               :end-val="cantBecadosAprobados"
               :duration="3000"
            ></countTo>
            Becas aprobadas
         </span>
         <br />
         <span class="font-weight-bold text-h5" style="color: #616161">
            <countTo
               :start-val="0"
               :end-val="cantBecadosAprobadosConsejo"
               :duration="3000"
            ></countTo>
            Becas aprobadas por consejo
         </span>
      </v-flex>
      <!-- POR TIPO BENEFICIARIO -->
      <v-flex xs12 sm8 pa-4>
         <v-data-table
            dense
            :mobile-breakpoint="0"
            :headers="headersTipoBeneficario"
            :items="datosTipoBeneficiario"
            item-key="name"
            disable-pagination
            hide-default-footer
            :loading="loadingTipoBeneficiario"
            loading-text="Cargando datos, por favor espere ..."
            no-data-text="Sin datos"
            class="elevation-1 mytable"
         >
            <template #item.monto="{ item }">
               $ {{ numFormatIntl(item.monto) }}
            </template>
            <template #body.append="{}">
               <tr>
                  <td class="font-weight-bold text-subtitle-1">
                     Total general
                  </td>
                  <td
                     style="text-align: right"
                     class="font-weight-bold text-subtitle-1"
                  >
                     $ {{ totalMontoTipoBeneficiario }}
                  </td>
                  <td
                     style="text-align: center"
                     class="font-weight-bold text-subtitle-1"
                  >
                     {{ totalNumBecaTipoBeneficiario }}
                  </td>
               </tr>
            </template>
         </v-data-table>
      </v-flex>
      <!-- BIENESTAR UNIVERSITARIO -->
      <v-flex xs12 sm4 pa-4 class="d-flex flex-column justify-space-between">
         <v-data-table
            dense
            :items="datosBienestar"
            :mobile-breakpoint="0"
            :loading="loadingBienestar"
            :headers="headersBienestar"
            item-key="key"
            disable-pagination
            hide-default-footer
            no-data-text="Sin datos"
            class="elevation-1 mytable"
         >
            <template #item.key="{ item }">
               {{ splitNombreBienestar(item.key) }}
            </template>
            <template #body.append="{}">
               <tr>
                  <td class="font-weight-bold text-subtitle-1">Total</td>
                  <td class="font-weight-bold text-subtitle-1 text-center">
                     {{ totalBienestar }}
                  </td>
               </tr>
            </template>
         </v-data-table>
      </v-flex>
      <v-flex xs12 pa-4>
         <v-card
            :class="{
               'd-flex flex-column align-center justify-center':
                  $vuetify.breakpoint.smAndDown,
               'd-flex flex-row align-center justify-center':
                  $vuetify.breakpoint.mdAndUp
            }"
         >
            <v-layout class="text-h6" align-center justify-center
               >Mostrar datos de tipo de beneficio por:</v-layout
            >
            <v-layout align-center justify-center>
               <span class="text-h6 pr-2">N° de becas</span>
               <v-switch v-model="showMontoTipoBeneficiario" color="primary">
               </v-switch>
               <span class="text-h6 pl-2">Suma total</span>
            </v-layout>
         </v-card>
      </v-flex>
      <v-flex xs12 sm6 pa-4>
         <v-card class="rounded-lg">
            <v-card-title> Becados por tipo de beneficiario </v-card-title>
            <apexchart
               height="350"
               width="100%"
               type="bar"
               :options="chBarTipoBeneficarioOptions"
               :series="chBarTipoBeneficarioSeries"
            ></apexchart>
         </v-card>
      </v-flex>
      <v-flex xs12 sm6 pa-4>
         <v-card class="rounded-lg">
            <v-card-title> Porcentajes por tipo de beneficiario </v-card-title>
            <apexchart
               height="350"
               width="100%"
               type="pie"
               :options="chPieTipoBeneficarioOptions"
               :series="chPieTipoBeneficarioSeries"
            ></apexchart>
         </v-card>
      </v-flex>

      <!-- POR CARRERA -->
      <v-flex xs12 sm12 pa-4>
         <v-data-table
            dense
            :mobile-breakpoint="0"
            :headers="headersCarrera"
            :items="datosCarrera"
            item-key="name"
            fixed-header
            :height="datosCarrera.length > 10 ? '450' : ''"
            disable-pagination
            hide-default-footer
            :loading="loadingCarrera"
            loading-text="Cargando datos, por favor espere ..."
            no-data-text="Sin datos"
            class="elevation-1 mytable"
         >
            <template #item.monto="{ item }">
               $ {{ numFormatIntl(item.monto) }}
            </template>
            <template #body.append="{}">
               <tr>
                  <td class="font-weight-bold text-subtitle-1">
                     Total general
                  </td>
                  <td
                     style="text-align: right"
                     class="font-weight-bold text-subtitle-1"
                  >
                     $ {{ totalMontoTipoBeneficiario }}
                  </td>
                  <td
                     style="text-align: center"
                     class="font-weight-bold text-subtitle-1"
                  >
                     {{ totalNumBecaTipoBeneficiario }}
                  </td>
               </tr>
            </template>
         </v-data-table>
      </v-flex>
      <v-flex xs12 sm12 pa-4>
         <v-card>
            <v-card-title>Mostrar datos de carreras por:</v-card-title>
            <v-layout align-center justify-center>
               <span class="text-h6 pr-2">N° de becas</span>
               <v-switch v-model="showMontoCarrera" color="primary"> </v-switch>
               <span class="text-h6 pl-2">Suma total</span>
            </v-layout>
         </v-card>
      </v-flex>
      <v-flex xs12 sm12 pa-4>
         <v-card class="rounded-lg">
            <v-card-title> Becados por carrera </v-card-title>
            <apexchart
               height="400"
               width="100%"
               type="bar"
               :options="chBarCarreraOptions"
               :series="chBarCarreraSeries"
            ></apexchart>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import countTo from 'vue-count-to'

export default {
   name: 'BecasDashboard',
   components: {
      apexchart: VueApexCharts,
      countTo: countTo
   },
   mixins: [formatters],
   data: (vm) => ({
      // Variables
      sede: process.env.VUE_APP_SEDE,
      cmbCiclos: [],
      selectCiclo: '',
      datosEstadistica: [],
      // Por tipo beneficiario
      showMontoTipoBeneficiario: true,
      loadingTipoBeneficiario: false,
      loadingBienestar: false,
      totalBienestar: 0,
      headersTipoBeneficario: [
         {
            text: 'Tipo beneficiario',
            value: 'tipoBeneficio',
            class: 'tituloPrimary'
         },
         {
            text: 'Suma total de ciclo',
            value: 'monto',
            align: 'right',
            class: 'tituloPrimary'
         },
         {
            text: 'Total de alumnos',
            value: 'numeroDeBecas',
            class: 'tituloPrimary',
            align: 'center'
         }
      ],
      datosTipoBeneficiario: [],
      chBarTipoBeneficarioOptions: {
         chart: {
            id: 'chBarTipoBeneficario'
         },
         colors: ['#A00402'],
         plotOptions: {
            bar: {
               horizontal: true,
               borderRadius: 2
            }
         },
         dataLabels: {
            enabled: false
         },
         xaxis: {
            categories: [
               // Fill with labels
            ],
            labels: {
               formatter: (value) => {
                  if (!vm.showMontoTipoBeneficiario) {
                     return vm.numFormatIntl(value)
                  } else {
                     return '$' + vm.numFormatIntl(value)
                  }
               }
            }
         },
         yaxis: {
            labels: {
               formatter: (value) => {
                  if (vm.numFormatIntl(value) == 'NaN') {
                     return value
                  } else {
                     if (!vm.showMontoTipoBeneficiario) {
                        return vm.numFormatIntl(value)
                     } else {
                        return '$' + vm.numFormatIntl(value)
                     }
                  }
               }
            }
         }
      },
      chBarTipoBeneficarioSeries: [
         {
            name: '',
            data: [
               // Fill with data
            ]
         }
      ],
      chPieTipoBeneficarioOptions: {
         chart: {
            id: 'chPieTipoBeneficario',
            toolbar: {
               show: true,
               tools: {
                  download: true
               }
            }
         },
         colors: [
            // Theme 1
            '#A00402',
            '#EDAE49',
            '#F9DF74',
            '#F9EDCC',
            '#AEA4BF',
            '#8F6593',
            '#F991CC'

            // Theme 2
            // '#A00402',
            // '#FF5376',
            // '#F8C0C8',
            // '#E2C290',
            // '#735D78',
            // '#283044',
            // '#78A1BB'
         ],
         labels: [
            // Fill with labels
         ]
      },
      chPieTipoBeneficarioSeries: [
         // Fill with data
      ],
      // Por carrera
      showMontoCarrera: true,
      loadingCarrera: false,
      headersCarrera: [
         {
            text: 'Carrera universitaria',
            value: 'carrera',
            class: 'tituloPrimary'
         },
         {
            text: 'Suma total de ciclo',
            value: 'monto',
            align: 'right',
            width: 190,
            class: 'tituloPrimary'
         },
         {
            text: 'Total de alumnos',
            value: 'numeroDeBecas',
            class: 'tituloPrimary',
            align: 'center'
         }
      ],
      headersBienestar: [
         {
            text: 'Tipo',
            value: 'key',
            class: 'tituloPrimary'
         },
         {
            text: 'Becas',
            value: 'val',
            class: 'tituloPrimary',
            align: 'center'
         }
      ],
      datosBienestar: [],
      datosCarrera: [],
      chBarCarreraOptions: {
         chart: {
            id: 'chBarCarrera'
         },
         colors: ['#A00402'],
         plotOptions: {
            bar: {
               borderRadius: 2
            }
         },
         dataLabels: {
            enabled: false
         },
         stroke: {
            width: 2
         },
         yaxis: {
            labels: {
               formatter: (value) => {
                  if (!vm.showMontoCarrera) {
                     return vm.numFormatIntl(value)
                  } else {
                     return '$' + vm.numFormatIntl(value)
                  }
               }
            }
         },
         xaxis: {
            categories: [
               // Fill with labels
            ],
            labels: {
               trim: true,
               hideOverlappingLabels: false
            }
         }
      },
      chBarCarreraSeries: [
         {
            name: '',
            data: [
               // Fill with data
            ]
         }
      ]
   }),
   computed: {
      ...authComputed,
      cantBecadosAprobados() {
         var total = 0
         this.datosEstadistica.forEach((el) => {
            total = Number(total) + Number(el.cantidad)
         })
         return total
      },
      cantBecadosAprobadosConsejo() {
         var total = 0
         this.datosEstadistica.forEach((el) => {
            total = Number(total) + Number(el.aprobadasPorConsejo)
         })
         return total
      },
      totalMontoTipoBeneficiario() {
         var total = 0
         if (this.datosTipoBeneficiario.length > 0) {
            this.datosTipoBeneficiario.forEach((el) => {
               total = Number(total) + Number(el.monto)
            })
         }
         return this.numFormatIntl(total)
      },
      totalNumBecaTipoBeneficiario() {
         var total = 0
         if (this.datosTipoBeneficiario.length > 0) {
            this.datosTipoBeneficiario.forEach((el) => {
               total = Number(total) + Number(el.numeroDeBecas)
            })
         }
         return this.numFormatIntl(total, 0)
      },
      totalMontoCarrera() {
         var total = 0
         if (this.datosCarrera.length > 0) {
            this.datosCarrera.forEach((el) => {
               total = Number(total) + Number(el.monto)
            })
         }
         return this.numFormatIntl(total)
      },
      totalNumBecaCarrera() {
         var total = 0
         if (this.datosCarrera.length > 0) {
            this.datosCarrera.forEach((el) => {
               total = Number(total) + Number(el.numeroDeBecas)
            })
         }
         return this.numFormatIntl(total, 0)
      }
   },
   watch: {
      selectCiclo() {
         this.listarDatos()
      },
      showMontoTipoBeneficiario() {
         // Update data
         this.updateDataTipoBeneficiario(this.datosTipoBeneficiario)
      },
      showMontoCarrera() {
         // Update data
         this.updateDataCarreras(this.datosCarrera)
      },
      datosTipoBeneficiario(val) {
         var newLabels = []
         val.forEach((el) => {
            newLabels.push(el.tipoBeneficio)
         })
         // Update labels
         this.chBarTipoBeneficarioOptions = {
            ...this.chBarTipoBeneficarioOptions,
            ...{
               xaxis: {
                  categories: newLabels
               }
            }
         }
         this.chPieTipoBeneficarioOptions = {
            ...this.chPieTipoBeneficarioOptions,
            ...{
               labels: newLabels
            }
         }

         // Update data
         this.updateDataTipoBeneficiario(val)
      },
      datosCarrera(val) {
         var newLabels = []
         val.forEach((el) => {
            newLabels.push(el.carrera)
         })
         // Update labels
         this.chBarCarreraOptions = {
            ...this.chBarCarreraOptions,
            ...{
               xaxis: {
                  categories: newLabels
               }
            }
         }
         // Update data
         this.updateDataCarreras(val)
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(4, 'ver')) {
         this.listarComboboxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      updateDataCarreras(val) {
         var newData = []
         val.forEach((el) => {
            if (this.showMontoCarrera) {
               newData.push(Number(el.monto))
            } else {
               newData.push(Number(el.numeroDeBecas))
            }
         })
         this.chBarCarreraSeries = [
            {
               name: '',
               data: newData
            }
         ]
      },
      splitNombreBienestar(nombre) {
         return nombre
            .split(/(?=[A-Z])/)
            .join(' ')
            .replace('numero', '')
      },
      updateDataTipoBeneficiario(val) {
         var newData = []
         val.forEach((el) => {
            if (this.showMontoTipoBeneficiario) {
               newData.push(Number(el.monto))
            } else {
               newData.push(Number(el.numeroDeBecas))
            }
         })
         this.chBarTipoBeneficarioSeries = [
            {
               name: '',
               data: newData
            }
         ]
         this.chPieTipoBeneficarioSeries = newData
      },
      listarComboboxes() {
         axios
            .get('api/SolicitudDeBeca/ciclosDisponibles')
            .then((response) => {
               this.cmbCiclos = response.data
               this.selectCiclo = response.data[response.data.length - 1]
            })
            .catch(() => {
               //console.log(error)
            })
      },
      listarDatos() {
         this.loadingTipoBeneficiario = true
         this.loadingCarrera = true
         this.loadingBienestar = true
         axios
            .get(
               'api/Beca/Reportes/Estadistica?periodoLectivo=' +
                  this.selectCiclo
            )
            .then((response) => {
               this.datosEstadistica = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get(
               '/api/Beca/Reportes/BienestarUniversitario?periodoLectivo=' +
                  this.selectCiclo
            )
            .then((response) => {
               this.datosBienestar = Object.entries(response.data).map(
                  ([key, val]) => ({
                     key,
                     val
                  })
               )
               this.datosBienestar.shift()
               this.totalBienestar = this.datosBienestar.pop().val
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.loadingBienestar = false
            })

         axios
            .get(
               'api/Beca/Reportes/PorBeneficio?periodoLectivo=' +
                  this.selectCiclo
            )
            .then((response) => {
               this.datosTipoBeneficiario = response.data.filter((tipo) => {
                  return tipo.tipoBeneficioId != 8 && tipo.tipoBeneficioId != 9
               })
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.loadingTipoBeneficiario = false
            })
         axios
            .get(
               'api/Beca/Reportes/PorCarrera?periodoLectivo=' + this.selectCiclo
            )
            .then((response) => {
               this.datosCarrera = response.data.filter((obj) => {
                  return obj.numeroDeBecas > 0
               })
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.loadingCarrera = false
            })
      }
   }
}
</script>
